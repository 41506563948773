<template>
  <v-sheet>
    <!-- <div ref="nose" class="d-flex justify-center pa-2">
      <v-btn @click.stop="message.show = true" x-large color="primary" rounded>
        <v-icon left>mdi-plus-thick</v-icon>
        Nuevo mensaje
      </v-btn>
    </div> -->

    <v-expand-transition>
      <div v-if="message.files.length > 0" >
        <div class="d-flex align-center">
          <v-subheader>
            {{ message.files.length }} archivos adjuntos
          </v-subheader>
          <v-btn icon @click.stop="message.files = []">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-divider />
      </div>
    </v-expand-transition>

    <div class="d-flex pa-2" style="gap: 10px">
      <v-btn fab small @click.stop="message.showFiles = true">
        <v-icon small>mdi-paperclip</v-icon>
      </v-btn>

      <v-textarea
        v-model="message.msg"
        filled
        auto-grow
        rows="1"
        hide-details
        dense
        placeholder="Escribe aquí tu mensaje..."
        @keydown.enter.prevent="() => message.msg == '' && message.files.length == 0 ? null : send()"
      >
      </v-textarea>

      <v-btn fab small color="primary" @click.stop="send" :disabled="message.msg == '' && message.files.length == 0" >
        <v-icon small>mdi-send</v-icon>
      </v-btn>
    </div>

    <v-dialog max-width="600px" v-model="message.showFiles">
      <v-card>
        <div class="pa-2">
          <v-subheader>Adjuntar archivos</v-subheader>
          <DragAndDrop multiple v-model="message.files" />
          <v-chip class="mt-2" small label
            >{{ message.files.length }} adjuntos</v-chip
          >
        </div>

        <v-card-actions>
          <v-spacer />
          <v-btn color="error" text @click.stop="message.showFiles = false; message.files = []">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click.stop="message.showFiles = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
export default {
  props: {
    value: Object,
    sendMsg : Function
  },
  components: {
    DragAndDrop: () => import("./DragAndDrop.vue"),
  },
  data() {
    return {
      message: {
        showFiles: false,
        msg: "",
        files: [],
      },
    };
  },
  methods: {
    send() {
      const formData = new FormData();
      if (!this.message.msg && this.message.files.length == 0) return;
      // formData.append("token", this.$store.getters.getToken);
      let msg = this.message.msg;

      this.message.msg = ''

      msg = msg.replace(
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig,
        (url) => {
          if (!/^https?:\/\//i.test(url)) url = "https://" + url;
          return `<a target="_blank" href="${url}">${url}</a>`;
        }
      );
      msg = msg.replace(/\r?\n/g, '<br />');
      
      formData.append("mensaje", msg);
      for (let i = 0; i < this.message.files.length; i++) {
        formData.append("documentos", this.message.files[i]);
      }


      axios({
        method: "POST",
        url: `/tickets`,
        data: formData,
      })
        .then(({ data }) => {
          this.cancelar()
          this.$emit("sent");
          this.sendMsg()
        })
        .catch((err) => console.error(err));
    },
    cancelar() {
      this.message.showFiles = false;
      this.message.msg = "";
      this.message.files = [];
    },
  },
};
</script>
